import { useState } from 'react'
import { motion } from 'framer-motion'
import { 
  UserIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline'
import openAIService from '../lib/openai'
import AIServiceStatus from '../components/AIServiceStatus'

const toneOptions = [
  'Professional', 'Casual', 'Friendly', 'Academic', 'Creative', 'Persuasive'
]

export default function AIHumanizer() {
  const [inputText, setInputText] = useState('')
  const [selectedTone, setSelectedTone] = useState('Professional')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const [showModal, setShowModal] = useState(false);
  
  const handleHumanize = async () => {
    if (!inputText.trim() || loading) return
    setLoading(true)
    setError(null)

    try { 
      // Show the modal first
      setShowModal(true)
      // Wait for a short delay before continuing with the text generation
      await new Promise(resolve => setTimeout(resolve, 2000))

      const humanizedText = await openAIService.humanizeText(inputText, selectedTone)
      setResult(humanizedText)
    } catch (err) {
      console.error("Humanization error:", err)
      setError("Failed to humanize text. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="bg-[#0D0118] min-h-screen">
      <section className="pt-32 pb-16 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-violet-600/10 via-fuchsia-500/5 to-transparent" />
          <div className="absolute top-0 left-0 w-full h-full" style={{
            backgroundImage: `linear-gradient(to right, rgba(139, 92, 246, 0.05) 1px, transparent 1px),
                             linear-gradient(to bottom, rgba(139, 92, 246, 0.05) 1px, transparent 1px)`,
            backgroundSize: '64px 64px'
          }} />
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center mb-16">
            {/* Add AI Service Status */}
            <AIServiceStatus />

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center px-6 py-2 rounded-full glass text-purple-300 border border-purple-500/20 mb-8"
            >
              <UserIcon className="h-5 w-5 mr-2" />
              AI Content Humanizer
            </motion.div>

            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="text-5xl sm:text-6xl font-bold mb-8"
            >
              Transform AI Text Into
              <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-violet-400 via-fuchsia-400 to-pink-500">
                Natural Writing
              </span>
            </motion.h1>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="max-w-4xl mx-auto glass rounded-2xl p-8"
            >
              <div className="grid md:grid-cols-2 gap-6 mb-6">
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">Original AI Text</label>
                  <textarea
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    className="w-full h-40 bg-purple-900/20 rounded-xl p-4 text-gray-100 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500/50"
                    placeholder="Paste your AI-generated text here..."
                    disabled={loading}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">Humanized Version</label>
                  <div className="w-full h-40 bg-purple-900/20 rounded-xl p-4 text-gray-300 overflow-auto text-left">
                    {error ? (
                      <span className="text-red-400">{error}</span>
                    ) : loading ? (
                      <div className="flex items-center gap-2">
                        <ArrowPathIcon className="h-5 w-5 animate-spin" />
                        Humanizing...
                      </div>
                    ) : result ? (
                      result
                    ) : (
                      'Humanized text will appear here...'
                    )}
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap gap-3 mb-6">
                {toneOptions.map((tone) => (
                  <button
                    key={tone}
                    onClick={() => setSelectedTone(tone)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                      selectedTone === tone
                        ? 'bg-violet-500 text-white'
                        : 'glass hover:bg-purple-900/30'
                    }`}
                  >
                    {tone}
                  </button>
                ))}

                {showModal && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
                      <h3 className="text-lg font-semibold mb-2">Demo Version</h3>
                      <p className="text-gray-600 mb-4">
                      The output shown is for demo purposes only. Sign in to experience the best results!
                      </p>
                      <button
                        onClick={() => setShowModal(false)}
                        className="w-full px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-violet-600 transition-colors"
                      >
                        Got it
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <button
                onClick={handleHumanize}
                disabled={loading || !inputText.trim()}
                className="w-full px-8 py-4 rounded-xl font-bold text-white bg-gradient-to-r from-violet-500 to-fuchsia-500 hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
              >
                {loading ? (
                  <>
                    <ArrowPathIcon className="h-5 w-5 animate-spin" />
                    Humanizing...
                  </>
                ) : (
                  'Humanize Text'
                )}
              </button>

              {/* Character count */}
              <div className="text-sm text-gray-400 mt-4 text-right">
                {inputText.length} characters
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  )
}