import { useState } from 'react'
import { motion } from 'framer-motion'

import { 
  MicrophoneIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline'
import AIServiceStatus from '../components/AIServiceStatus'

const voices = [
  { id: 'sarah', name: 'Sarah', lang: 'English (US)', type: 'Female', accent: 'American' },
  { id: 'james', name: 'James', lang: 'English (UK)', type: 'Male', accent: 'British' },
  { id: 'maria', name: 'Maria', lang: 'Spanish', type: 'Female', accent: 'Spanish' },
  { id: 'alex', name: 'Alex', lang: 'English (US)', type: 'Male', accent: 'American' },
  { id: 'emma', name: 'Emma', lang: 'English (AU)', type: 'Female', accent: 'Australian' },
  { id: 'hans', name: 'Hans', lang: 'German', type: 'Male', accent: 'German' }
]

export default function VoiceoverAI() {
  const [text, setText] = useState('')
  const [selectedVoice, setSelectedVoice] = useState(voices[0])
  const [speed, setSpeed] = useState(1)
  const [pitch, setPitch] = useState(1)
  const [emotion, setEmotion] = useState(0.5)
  const [loading, setLoading] = useState(false)
  const [_, setAudioUrl] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false);

  const handleGenerate = async () => {
    if (!text.trim() || loading) return
    setLoading(true)

    try {
      // Show the modal first
      setShowModal(true)
      // Wait for a short delay before continuing with the text generation
      await new Promise(resolve => setTimeout(resolve, 1000))

      setAudioUrl('https://example.com/audio.mp3')
    } catch (err) {
      console.error('Error generating audio:', err)
    } finally {
      setLoading(false)
    }
  }


  const handleRedirectClose = async () => {
    setShowModal(false)
    window.location.href = '/pricing';
  }
  return (
    <div className="bg-[#0D0118] min-h-screen pt-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <AIServiceStatus />

        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-flex items-center px-6 py-2 rounded-full glass text-purple-300 border border-purple-500/20 mb-8"
          >
            <MicrophoneIcon className="h-5 w-5 mr-2" />
            Text to Speech AI
          </motion.div>

          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-5xl sm:text-6xl font-bold mb-8"
          >
            Transform Text Into
            <br />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-400 via-amber-400 to-yellow-500">
              Natural Speech
            </span>
          </motion.h1>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="max-w-4xl mx-auto glass rounded-2xl p-8"
          >
            {/* Text Input */}
            <div className="mb-6">
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="w-full h-32 bg-purple-900/20 rounded-xl p-4 text-gray-100 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500/50"
                placeholder="Enter your text to convert to speech..."
              />
            </div>

            <div className="grid sm:grid-cols-2 gap-6 mb-6">
              {/* Voice Selection */}
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">Select Voice</label>
                <div className="grid grid-cols-2 gap-3">
                  {voices.map((voice) => (
                    <button
                      key={voice.id}
                      onClick={() => setSelectedVoice(voice)}
                      className={`px-4 py-3 rounded-xl text-left transition-colors ${
                        selectedVoice.id === voice.id
                          ? 'bg-orange-500/20 border border-orange-500/50'
                          : 'glass hover:bg-purple-900/30'
                      }`}
                    >
                      <div className="font-medium">{voice.name}</div>
                      <div className="text-sm text-gray-400">{voice.lang}</div>
                    </button>
                  ))}
                </div>
              </div>

              {/* Voice Settings */}
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">Voice Settings</label>
                <div className="space-y-4">
                  <div>
                    <div className="flex justify-between text-sm text-gray-400 mb-1">
                      <span>Speed</span>
                      <span>{speed}x</span>
                    </div>
                    <input
                      type="range"
                      min="0.5"
                      max="2"
                      step="0.1"
                      value={speed}
                      onChange={(e) => setSpeed(parseFloat(e.target.value))}
                      className="w-full"
                    />
                  </div>
                  <div>
                    <div className="flex justify-between text-sm text-gray-400 mb-1">
                      <span>Pitch</span>
                      <span>{pitch}</span>
                    </div>
                    <input
                      type="range"
                      min="0.5"
                      max="1.5"
                      step="0.1"
                      value={pitch}
                      onChange={(e) => setPitch(parseFloat(e.target.value))}
                      className="w-full"
                    />
                  </div>
                  <div>
                    <div className="flex justify-between text-sm text-gray-400 mb-1">
                      <span>Emotion</span>
                      <span>{Math.round(emotion * 100)}%</span>
                    </div>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.1"
                      value={emotion}
                      onChange={(e) => setEmotion(parseFloat(e.target.value))}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Generate Button */}
            <button
              onClick={handleGenerate}
              disabled={loading || !text.trim()}
              className="w-full px-8 py-4 rounded-xl font-bold text-white bg-gradient-to-r from-orange-500 to-amber-500 hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
            >
              {loading ? (
                <>
                  <ArrowPathIcon className="h-5 w-5 animate-spin" />
                  Generating...
                </>
              ) : (
                'Generate Speech'
              )}
            </button>

            {/* Audio Player */}
            {/* {audioUrl && (
              <div className="mt-6 p-4 glass rounded-xl">
                <audio controls className="w-full">
                  <source src={audioUrl} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            )} */}

            {showModal && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
                  <h3 className="text-lg font-semibold mb-2">Demo Version</h3>
                  <p className="text-gray-600 mb-4">
                  Please Signin to get your custom voiceover results!
                  </p>
                  <button
                    onClick={handleRedirectClose}
                    className="w-full px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-violet-600 transition-colors"
                  >
                    Got it
                  </button>
                </div>
              </div>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  )
}
