import { useState } from 'react'
import { motion } from 'framer-motion'
import { SparklesIcon, ArrowPathIcon } from '@heroicons/react/24/outline'
import openAIService from '../lib/openai'
import AIServiceStatus from '../components/AIServiceStatus'

export default function AIDetector() {
  const [text, setText] = useState('')
  const [result, setResult] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false);

  const handleAnalyze = async () => {
    if (!text.trim() || loading) return

    setLoading(true)
    setError(null)
    setResult(null)

    try {
      // Show the modal first
      setShowModal(true)
      // Wait for a short delay before continuing with the text generation
      await new Promise(resolve => setTimeout(resolve, 2000))

      const analysis = await openAIService.detectAIContent(text)
      setResult(analysis)
    } catch (err) {
      setError('Failed to analyze text. Please try again.')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="bg-[#0D0118] min-h-screen pt-32">
      <div className="max-w-4xl mx-auto px-4">
        <AIServiceStatus />
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-8"
        >
          <SparklesIcon className="h-12 w-12 mx-auto mb-4 text-purple-400" />
          <h1 className="text-4xl font-bold mb-4">AI Content Detector</h1>
          <p className="text-gray-400">Analyze text to detect AI-generated content</p>
        </motion.div>

        <div className="glass rounded-xl p-6">
          <textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="w-full h-40 bg-purple-900/20 rounded-xl p-4 text-gray-100 mb-4"
            placeholder="Paste your text here to analyze..."
          />
          <button
            onClick={handleAnalyze}
            disabled={loading || !text.trim()}
            className="w-full py-3 rounded-xl bg-purple-500 text-white font-medium flex items-center justify-center gap-2"
          >
            {loading ? (
              <>
                <ArrowPathIcon className="h-5 w-5 animate-spin" />
                Analyzing...
              </>
            ) : (
              'Analyze Text'
            )}
          </button>

          {error && (
            <div className="mt-4 p-4 bg-red-500/10 text-red-400 rounded-xl">
              {error}
            </div>
          )}

          {result && (
            <div className="mt-4 p-4 bg-purple-900/20 rounded-xl">
              <pre className="whitespace-pre-wrap text-gray-300">{result}</pre>
            </div>
          )}
          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
                <h3 className="text-lg font-semibold mb-2">Demo Version</h3>
                <p className="text-gray-600 mb-4">
                The output shown is for demo purposes only. Sign in to experience the best results!
                </p>
                <button
                  onClick={() => setShowModal(false)}
                  className="w-full px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-violet-600 transition-colors"
                >
                  Got it
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
