import { useState } from 'react'
import { motion } from 'framer-motion'
import { 
  PencilSquareIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline'
import openAIService from '../lib/openai'
import AIServiceStatus from '../components/AIServiceStatus'

const contentTypes = [
  { id: 'blog', name: 'Blog Post', description: 'Long-form article with SEO optimization' },
  { id: 'social', name: 'Social Media', description: 'Engaging posts for social platforms' },
  { id: 'email', name: 'Email', description: 'Professional email communications' },
  { id: 'product', name: 'Product Description', description: 'Compelling product content' },
  { id: 'ad', name: 'Ad Copy', description: 'Persuasive advertising content' },
  { id: 'script', name: 'Video Script', description: 'Engaging video content' }
]

export default function AIWriter() {
  const [selectedType, setSelectedType] = useState(contentTypes[0])
  const [prompt, setPrompt] = useState('')
  const [keywords, setKeywords] = useState('')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false);

  const handleGenerate = async () => {
    if (!prompt.trim() || loading) return

    setLoading(true)
    setError(null)

    try {
      // Show the modal first
      setShowModal(true)
      // Wait for a short delay before continuing with the text generation
      await new Promise(resolve => setTimeout(resolve, 2000))

      const fullPrompt = `Type: ${selectedType.name}\nKeywords: ${keywords}\nPrompt: ${prompt}`
      console.log("Generating content with prompt:", fullPrompt)
      const content = await openAIService.generateContent(fullPrompt, selectedType.name)
      setResult(content)
    } catch (err) {
      console.error("Content generation error:", err)
      setError("Failed to generate content. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="bg-[#0D0118] min-h-screen">
      <section className="pt-32 pb-16 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-fuchsia-600/10 via-pink-500/5 to-transparent" />
          <div className="absolute top-0 left-0 w-full h-full" style={{
            backgroundImage: `linear-gradient(to right, rgba(139, 92, 246, 0.05) 1px, transparent 1px),
                             linear-gradient(to bottom, rgba(139, 92, 246, 0.05) 1px, transparent 1px)`,
            backgroundSize: '64px 64px'
          }} />
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center mb-16">
            {/* Add AI Service Status at the top */}
            <AIServiceStatus />

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center px-6 py-2 rounded-full glass text-purple-300 border border-purple-500/20 mb-8"
            >
              <PencilSquareIcon className="h-5 w-5 mr-2" />
              AI Content Writer
            </motion.div>

            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="text-5xl sm:text-6xl font-bold mb-8"
            >
              Create Engaging
              <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-fuchsia-400 via-pink-400 to-orange-500">
                Content Instantly
              </span>
            </motion.h1>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="max-w-4xl mx-auto glass rounded-2xl p-8"
            >
              {/* Content Type Selection */}
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-6">
                {contentTypes.map((type) => (
                  <button
                    key={type.id}
                    onClick={() => setSelectedType(type)}
                    className={`p-4 rounded-xl text-left transition-colors ${
                      selectedType.id === type.id
                        ? 'bg-fuchsia-500/20 border border-fuchsia-500/50'
                        : 'glass hover:bg-purple-900/30'
                    }`}
                  >
                    <div className="font-medium">{type.name}</div>
                    <div className="text-sm text-gray-400">{type.description}</div>
                  </button>
                ))}
              </div>

              {/* Keywords Input */}
              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-400 mb-2">Keywords</label>
                <input
                  type="text"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  className="w-full bg-purple-900/20 rounded-xl p-4 text-gray-100 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500/50"
                  placeholder="Enter keywords separated by commas..."
                />
              </div>

              {/* Prompt Input */}
              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-400 mb-2">What would you like to write about?</label>
                <textarea
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  className="w-full h-32 bg-purple-900/20 rounded-xl p-4 text-gray-100 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500/50"
                  placeholder="Describe what you want to write about..."
                />
              </div>

              {/* Generate Button */}
              <button
                onClick={handleGenerate}
                disabled={loading || !prompt.trim()}
                className="w-full px-8 py-4 rounded-xl font-bold text-white bg-gradient-to-r from-fuchsia-500 to-pink-500 hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
              >
                {loading ? (
                  <>
                    <ArrowPathIcon className="h-5 w-5 animate-spin" />
                    Generating...
                  </>
                ) : (
                  'Generate Content'
                )}
              </button>

              {/* Error Display */}
              {error && (
                <div className="mt-6 p-4 bg-red-500/10 border border-red-500/20 rounded-xl">
                  <p className="text-red-400">{error}</p>
                </div>
              )}

              {/* Result Display */}
              {result && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-6 p-6 glass rounded-xl"
                >
                  <h3 className="text-xl font-bold mb-4">Generated Content</h3>
                  <div className="text-gray-300 whitespace-pre-wrap">{result}</div>
                </motion.div>
              )}

              {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
                    <h3 className="text-lg font-semibold mb-2">Demo Version</h3>
                    <p className="text-gray-600 mb-4">
                    The output shown is for demo purposes only. Sign in to experience the best results!
                    </p>
                    <button
                      onClick={() => setShowModal(false)}
                      className="w-full px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-violet-600 transition-colors"
                    >
                      Got it
                    </button>
                  </div>
                </div>
              )}
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  )
}